import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RichText from './RichText';
import globalProps from '../props';
import theme from '../theme';

export const CardWrapper = styled.section`
  height: auto;
  padding: .5rem;
  width: ${({ small }) => small ? '25%' : '33.3%'};

  @media (max-width: 992px){
    width: 50%;
  }

  @media (max-width: 618px){
    min-width: auto;
    width: 100%;
  }
`;

const CardBody = styled.div`
  background-image:  url("/corners.svg"), linear-gradient(
  ${({ index }) => {
    let color = theme.primary;
    if (index === 1) {
      color = theme.tertiary;
    } else if (index === 2) {
      color = theme.secondary;
    }

    return `${color}, ${color}`;
  }});

  background-position: top;
  background-size: cover;
  border-radius: ${theme.borderRadius};
  box-shadow: 2px 3px 13px ${theme.boxShadow};
  color: ${theme.contrast};
  height: 100%;
  padding: 8vh 4vw 2vh 2vw;
  transition-duration: 1s;
  transition-property: 
    box-shadow, 
    transform;

  @media (max-width: 618px){
    padding: 6vh 4vw 2vh;
  }

  p {
    color: inherit;
  }
`;

const CardTitle = styled.h2`
    color: ${theme.contrast};
    font-family: inherit;
    font-size: 1.333em;
    font-weight: 400;
`;

const Card = ({ description, title, num }) => (
  <CardWrapper>
    <CardBody index={num + 1}>
      <CardTitle>{title}</CardTitle>
      <RichText body={description} />
    </CardBody>
  </CardWrapper>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: globalProps.body,
  num: PropTypes.number,
};

Card.defaultProps = {
  description: null,
  num: 0,
};

export default Card;
