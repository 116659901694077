import React from 'react';
import styled from 'styled-components';
import HubspotForm from 'react-hubspot-form';
import theme from '../theme';
import Container from './Container';
import RichText from './RichText';

const FormContainer = styled.div`
    background-color: ${theme.contrast};
    border-radius: ${theme.borderRadius};
    margin: 0 .5vw;
    padding: 6vh 8vw;

    input:not([type="checbox"]),
    input:not([type="radio"]),
    select {
        border-color: ${theme.dark}60;
        width: 49%;

        &::placeholder {
            color: ${theme.dark}50;
        }

        &:focus {
            border-color: ${theme.success};
        }
    } 
`;

const SupportForm = ({ id, body }) => (
  <Container>
    <FormContainer>
      <RichText body={body} />
      <br />
      <HubspotForm
        portalId="1771995"
        formId={id}
        onSubmit={() => console.log('Submit!')}
        onReady={form => console.log('Form ready!')}
        loading={<div>Loading...</div>}
      />
    </FormContainer>
  </Container>
);

export default SupportForm;
