import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import styled from "styled-components";
import App from "../components/App";
import SEO from "../components/SEO";
import HeroInterior from "../components/HeroInterior";
import Panel from "../components/Panel";
import Container from "../components/Container";
import Grid from "../components/Grid";
import CardLink from "../components/CardLink";
import SupportForm from "../components/SupportForm";
import Wrapper from "../components/Wrapper";
import QuickCall from "../components/QuickCall";

const Map = styled.iframe`
  height: 450px;
  margin: 2rem auto;
  min-width: 300;
  width: 40%;

  @media (max-width: 946px) {
    height: 300px;
    width: 100%;
  }
`;

const ContactContainer = styled(Container)`
  margin-top: -120px;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export default function ContactPage({ data }) {
  const { contentfulContactPage, contentfulPanel } = data;
  const { header, cards, formId, body } = contentfulContactPage;

  return (
    <App>
      <SEO {...contentfulContactPage} />
      <HeroInterior
        title={header.title}
        subtitle={get(header, "subtitle.subtitle")}
        version="green"
      />
      <Wrapper variant="muted">
        {/** If template contains cards */
        cards && (
          <ContactContainer>
            <Grid justify="center">
              {/** ideally, should only render 3 */
              cards.map((card, i) => (
                <CardLink {...card} num={i} key={card.contentful_id} />
              ))}
            </Grid>
          </ContactContainer>
        )}

        <SupportForm id={formId} body={body} />
      </Wrapper>
      <Container large>
        <Panel
          {...contentfulPanel}
          imagePos={
            <Map
              src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2883.2732767133202!2d-79.35096698423413!3d43.725649555760306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4cd1199b74007%3A0x2cc1c0ac672d5e97!2s510-1131A%20Leslie%20St%2C%20North%20York%2C%20ON%20M3C%203L8!5e0!3m2!1sen!2sca!4v1643925415723!5m2!1sen!2sca"
              frameBorder="0"
              allowFullscreen
            />
          }
          subContent={
            <Grid justify="space-between">
              <QuickCall location="In Toronto" num="416.360.2646" />
              <QuickCall location="Toll-Free" num="855.660.2646" />
            </Grid>
          }
        />
      </Container>
    </App>
  );
}

export const query = graphql`
  query getContentfulContactPageById($contentful_id: String) {
    contentfulContactPage(contentful_id: { eq: $contentful_id }) {
      title
      description {
        description
      }
      body {
        childContentfulRichText {
          html
        }
      }
      header {
        ...HeroDetail
      }
      formId
      cards {
        ...CardDetail
      }
    }

    contentfulPanel(contentful_id: { eq: "2DkghzrvpEO3JttwAUSbpr" }) {
      contentful_id
      leader {
        leader
      }
      title
      body {
        childContentfulRichText {
          html
        }
      }
      roundedImage
      image {
        title
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;
