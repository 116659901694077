import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Icons from 'react-icons/fi';
import { CardWrapper } from './Card';
import { Label } from './AuthorWidget';
import RichText from './RichText';
import globalProps from '../props';
import theme from '../theme';
import { ButtonLinkStyle } from './Button';

export const CardBody = styled.div`
  background-color: ${theme.contrast};
  border-radius: ${theme.borderRadius};
  color: ${theme.dark};
  display: block;
  height: 100%;
  padding: 3vh 2vw;
  text-align: center;
  text-decoration: none;

  @media (max-width: 618px){
    padding: 2vh 3vw;
  }
`;

const LinkOut = styled.a`
  ${ButtonLinkStyle};
  box-sizing: border-box;
`;

const Icon = styled.div`
  background-color: ${theme.primary}10;
  border-radius: 500px;
  display: inline-block;
  margin: 0 auto 1rem auto;
  padding: 1rem;
  position: relative;
  height: 4rem;
  width: 4rem;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export default function Card({
  description,
  title,
  buttonLabel,
  buttonDestination,
  icon,
  small,
}) {
  const CustomIcon = Icons[icon];
  return (
    <CardWrapper small={small}>
      <CardBody>
        {CustomIcon && (
          <Icon>
            <CustomIcon color={theme.secondary} />
          </Icon>
        )}
        <Label>
          {title}
        </Label>
        <RichText
          center
          body={description}
        />
        {buttonLabel && (
          <LinkOut
            fill="secondary"
            href={buttonDestination}
            small
          >
            {buttonLabel}
          </LinkOut>
        )}
      </CardBody>
    </CardWrapper>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: globalProps.body,
  small: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDestination: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

Card.defaultProps = {
  description: null,
};
