import React from 'react';
import styled from 'styled-components';
import { FiPhoneCall } from 'react-icons/fi';
import theme from '../theme';
import Grid from './Grid';

const LinkStyle = styled.a`
  background-color: ${theme.contrast};
  border-radius: ${theme.borderRadius};
  box-shadow: 3px 4px 14px ${theme.boxShadow};
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  padding: 3vh 2vw;
  width: 48%;
`;

const LinkTitle = styled.strong`
  color: ${theme.dark};
`;

const LinkText = styled.p`
  margin: 0;
  text-transform: uppercase;
  width: 75%;
`;

const IconBackground = styled.div`
  background-color: ${theme.darkMuted};
  border-radius: 50%;
  padding: .5rem;
  height: 2rem;
  width: 2rem;

  svg {
    stroke: ${theme.secondary};
    width: 100%;
    height: 100%;
  }
`;

export default function QuickCall({ num, location }) {
  return (
    <LinkStyle href={`tel:${num}`}>
      <Grid justify="space-between">
        <IconBackground>
          <FiPhoneCall />
        </IconBackground>
        <LinkText>
          <LinkTitle>{location}</LinkTitle>
          <br />
          <small>{num}</small>
        </LinkText>
      </Grid>
    </LinkStyle>
  );
}
