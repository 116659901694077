import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import styled, { css } from 'styled-components';
import Container from './Container';
import Grid from './Grid';
import RichText from './RichText';
import { styledList } from './List';
import theme from '../theme';
import globalProps from '../props';

const PanelContainer = styled.div`
    background-color: ${({ transparent }) => transparent
    ? 'transparent'
    : theme.contrast};
    border-radius: ${theme.borderRadius};
    margin: 0 0 3vh;
    
    ${styledList}
`;

const PanelGrid = styled(Grid)`
    flex-direction: ${({ reverseRow }) => reverseRow ? 'row-reverse' : 'row'};
    
    @media (max-width: 768px){
        flex-direction: row;
    }
`;


const PanelBody = styled.section`
    padding: 2vh 2vw;
    width: 50%;

    @media (max-width: 947px){
        width: 100%;
    }
`;

const Leader = styled.p`
  font-size: 1.138em;
  margin-bottom: 1rem;
`;

const PanelTitle = styled.h2`
    font-size: calc(1.602em + .5vw);
    margin: 1rem 0 0;

    &::after {
        background-color: ${theme.success};
        content: "";
        display: block;
        margin-bottom: 1rem;
        margin-top: 1rem;
        height: 2px;
        width: 110px;
    }
`;

const PanelImage = styled(Image)`
  height: 0;
  ${({ roundedImage }) => {
    return roundedImage
      ? css`
        border-radius: 50%;
        padding-top: 40%;
        width: 40%;
        @media (max-width: 947px){
          padding-top: 350px;
          width: 350px;
        }

        @media (max-width: 493px){
          padding-top: 250px;
          width: 250px;
        }
      `
      : css`
        height: auto !important;
        width: 45%;
        
        @media (max-width: 947px){
          width: 100%;
        }
      `;
  }};
`;

export default function Panel({
  body,
  image,
  reverse,
  title,
  leader,
  roundedImage,
  imagePos,
  subContent,
  transparent,
}) {
  return (
    <PanelContainer transparent={transparent}>
      <Container large>

        {/** center title with leader */
          leader && (
            <Container small center>
              <h2 style={{ marginTop: '1rem' }}>
                {title}
              </h2>
              <Leader>
                {leader.leader}
              </Leader>
            </Container>
          )}

        <PanelGrid
          reverseRow={reverse}
          align="center"
          justify="space-around"
        >
          {(image && !imagePos) ? (
            <PanelImage
              roundedImage={roundedImage}
              fluid={image.fluid}
              alt={image.title}
              imgStyle={{
                objectFit: roundedImage ? 'cover' : 'contain',
              }}
            />
          ) : imagePos}
          <PanelBody>
            {!leader && (
              <PanelTitle>
                {title}
              </PanelTitle>
            )}
            <div style={{ width: '85%' }}>
              <RichText body={body} />
            </div>
            {subContent}
          </PanelBody>
        </PanelGrid>
      </Container>
    </PanelContainer>
  );
}

Panel.propTypes = {
  reverse: PropTypes.bool,
  title: PropTypes.string.isRequired,
  image: globalProps.image.isRequired,
  body: globalProps.body,
};

Panel.defaultProps = {
  body: null,
  reverse: false,
};
